<template>
  <div v-if="items.length > 0" class="item-list">
    <p class="title">{{ title }}</p>
    <div class="scrollable-body">
      <table class="custom-table">
        <thead>
          <tr>
            <th>Articolo</th>
            <th>Quantità</th>
            <th>Unità di Misura</th> <!-- Aggiunta della colonna per l'unità di misura -->
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.articolo" :class="{ 'articleAlreadyPresent': item.incurrentinventory }">
            <td>{{ item.articolo }}</td>
            <td class="quantity">{{ item.quantità }}</td>
            <td>{{ item.unità_misura }}</td> <!-- Visualizzazione dell'unità di misura -->
            <td class="trashDeleteContainer">
              <img 
                src="../../../public/images/trash-bin.png" 
                alt="Elimina" 
                class="trashDelete" 
                @click="handleDelete(item.articolo)"
              >
              <img 
                src="../../assets/edit.png"
                alt="Modifica" 
                class="editElementImg"
                @click="handleEdit(item)"
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <p v-else class="empty-message">{{ emptyMessage }}</p>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    emptyMessage: {
      type: String,
      default: 'Non ci sono articoli.'
    }
  },
  methods: {
    handleDelete(articolo) {
      this.$emit('delete-item', articolo);
    },
    handleEdit(item) {
      this.$emit('edit-item', item); // Emitting the edit event
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../scss/styles.scss";


.title {
  margin: 1rem 0; /* Margine superiore e inferiore */
}

.scrollable-body {
  max-height: 65vh; /* Imposta l'altezza massima per la parte scorrevole */
  overflow-y: auto; /* Abilita lo scrolling verticale */
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th, .custom-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.custom-table th {
  background-color: #f2f2f2;
  position: sticky;
  top: 0;
  z-index: 10;
}

.quantity {
  text-align: center;
}

.articleAlreadyPresent {
  text-decoration: line-through;
}

.empty-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
