<template>
  <LoadingSpinner v-if="loading" />
  <div v-else>
    <!-- Overlay che opacizza lo sfondo -->
    <div v-if="showModal" class="overlay"></div>

    <!-- Notifica -->
    <NotificationMessage :message="notification.message" :type="notification.type" />

    <div class="bottom-fixed-container" v-if="!showModal">
      <button @click="showModal = true" class="addArticleBtn" role="button">Aggiungi articolo</button>
    </div>
    <div>
      <formCreateNewItem 
        v-if="showModal"
        :isVisible="showModal"
        :addItem="addItem"
        :section="'mustbe'"
        :item="selectedItem"
        class="modal-form"
        @add-item="handleAddItemFromModal"
        @update-item="handleUpdateItemFromModal"
        @close="showModal = false"
      />
      
    </div>

    <ItemList 
      :items="mustBeItems"
      title="Questo è quello che non può mancare a casa"
      emptyMessage="Non hai nulla di obbligatorio"
      @delete-item="handleDeleteItem"
      @edit-item="openEditItemModal"
    />

    <p id="note" v-if="hasCrossedItems">
      * <span class="crossed-text">articolo sbarrato</span> &#8594; già presente in inventario
    </p>

    <div class="bottom-fixed-container" v-if="!showModal">
      <button @click="showModal = true" class="addArticleBtn" role="button">Aggiungi articolo</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { addItem, deleteItem } from '../main';
import formCreateNewItem from './formCreateNewItem.vue';
import LoadingSpinner from './utils/LoadingSpinner.vue';
import ItemList from './utils/ItemList.vue';
import NotificationMessage from './utils/NotificationMessage.vue';

export default {
  components: {
    formCreateNewItem,
    LoadingSpinner,
    ItemList,
    NotificationMessage
  },
  data() {
    return {
      mustBeItems: [],
      newItem: {
        articolo: '',
        quantità: '',
      },
      showModal: false,
      loading: true,
      selectedItem: null,
      currentInventory: [],
      notification: {
        message: '',
        type: '' // 'success' o 'error'
      },
    };
  },
  created() {
    this.fetchMustBe();
    this.fetchCurrentInventory(); // Recupera anche l'inventario corrente al caricamento
  },
  computed: {
    hasCrossedItems() {
      return this.mustBeItems.some(item => item.incurrentinventory);
    }
  },
  methods: {
    addItem(newItem, section) {
      return addItem(newItem, section);
    },
    handleAddItemFromModal(newItem) {
      const existingItemIndex = this.mustBeItems.findIndex(item => 
        item.articolo === newItem.articolo && item.unità_misura === newItem.unità_misura
      );

      if (existingItemIndex !== -1) {
        this.mustBeItems[existingItemIndex].quantità += newItem.quantità;
      } else {
        this.mustBeItems.push(newItem);
      }

      this.updateInCurrentInventory();
      this.showModal = false;
    },
    handleDeleteItem(articolo) {
      deleteItem(articolo, 'mustbe')
        .then(() => {
          this.mustBeItems = this.mustBeItems.filter(item => item.articolo !== articolo);
          this.showNotification('Articolo eliminato con successo', 'success');
        })
        .catch((error) => {
          console.error('Errore nell\'eliminare l\'articolo:', error);
          this.showNotification('Errore durante l\'eliminazione', 'error');
        });
    },
    async fetchMustBe() {
      try {
        const response = await axios.get('http://127.0.0.1:5000/api/mustbe');
        this.mustBeItems = response.data.map(item => ({
          articolo: item.articolo,
          quantità: item.quantità,
          unità_misura: item.unità_misura,
          incurrentinventory: item.incurrentinventory
        }));
      } catch (error) {
        console.error('Errore nel recupero dell\'inventario:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchCurrentInventory() {
      try {
        const response = await axios.get('http://127.0.0.1:5000/api/inventory');
        this.currentInventory = response.data.map(item => ({
          articolo: item.articolo,
          quantità: item.quantità,
          unità_misura: item.unità_misura // Includi unità di misura
        }));
      } catch (error) {
        console.error('Errore nel recupero dell\'inventario corrente:', error);
      }
    },
    openAddItemModal() {
      this.selectedItem = null;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
      this.selectedItem = null;
    },
    openEditItemModal(item) {
      this.selectedItem = { ...item };
      this.showModal = true;
    },
    showNotification(message, type) {
      this.notification.message = message;
      this.notification.type = type;

      setTimeout(() => {
        this.notification.message = '';
      }, 3000);
    },
    async handleUpdateItemFromModal(updatedItem) {
      try {
        await axios.put(`http://127.0.0.1:5000/api/mustbe/${updatedItem.articolo}`, {
          quantità: updatedItem.quantità,
          unità_misura: updatedItem.unità_misura // Include l'unità di misura nell'aggiornamento
        });
        this.fetchMustBe();
        this.closeModal();
        this.showNotification('Articolo aggiornato con successo', 'success');
      } catch (error) {
        console.error('Errore nell\'aggiornamento dell\'articolo:', error);
        this.showNotification('Errore durante l\'aggiornamento dell\'articolo', 'error');
      }
    },
    updateInCurrentInventory() {
      this.mustBeItems.forEach(item => {
        const inventoryItem = this.currentInventory.find(invItem => 
          invItem.articolo === item.articolo && invItem.unità_misura === item.unità_misura
        );
        if (inventoryItem) {
          // Controlla se la quantità in inventario è maggiore o uguale
          item.incurrentinventory = inventoryItem.quantità >= item.quantità;
        } else {
          item.incurrentinventory = false; // Non è presente nell'inventario
        }
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.container {
  margin-top: 20px;
}
.button {
  margin-bottom: 20px;
}
.table {
  width: 100%;
}

#note {
  position: fixed;
  bottom: 100px; 
  font-size: 10px;
}

.crossed-text {
  text-decoration: line-through;
}

</style>
