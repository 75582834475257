<template>
    <LoadingSpinner v-if="loading" />
    <div v-else>

        <div id="ulContainer" v-if="shoppingList.length > 0">
            <p>
                Inserisci qui tutto ciò che vorresti acquistare &#128512;
            </p>
            <ul>
                <li v-for="(item, index) in shoppingList" :key="item.articolo">
                    <div class="checkbox-wrapper-15">
                        <div>
                            <input 
                            class="inp-cbx" 
                            :id="`cbx-${index}`" 
                            type="checkbox" 
                            style="display: none;" 
                            v-model="item.checked"
                            />
                            <label class="cbx" :for="`cbx-${index}`">
                                <span>
                                    <svg width="12px" height="9px" viewbox="0 0 12 9">
                                        <polyline points="1 5 4 8 11 1"></polyline>
                                    </svg>
                                </span>
                                <span>{{ item.articolo }}: {{ item.quantità }} {{ item.unità_misura }}</span> <!-- Mostra l'unità di misura -->
                            </label>
                        </div>
                        
                        <span class="trashDeleteContainer">
                            <img 
                                src="../../public/images/trash-bin.png" 
                                alt="Elimina" 
                                class="trashDelete btn"
                                @click="handleDeleteItem(item.articolo)"
                            >
                        </span>
                    </div>
                </li>
            </ul>
        </div>
        

        <!-- Overlay che opacizza lo sfondo -->
        <div v-if="showModal" class="overlay"></div>

        <div class="bottom-fixed-container" v-if="!showModal">
            <button @click="showModal = true" class="addArticleBtn" role="button">Aggiungi articolo</button>
            <button @click="finishShopping" class="addArticleBtn" role="button">Spesa finita</button>
        </div>
        <div>
            <formCreateNewItem 
                v-if="showModal"
                :isVisible="showModal"
                :addItem="addItem"
                :section="'shoppingList'"
                class="modal-form"
                @add-item="handleAddItemFromModal"
                @close="showModal = false"
            />
        </div>

        <!-- Mostra un messaggio se l'inventario è vuoto -->
        <p v-if="shoppingList.length === 0" id="emptyShoppingList">La tua shopping list è vuota, hai tutto a casa! 🥳</p>

    </div>

</template>
  
<script>
import axios from 'axios';
import { addItem, deleteItem } from '../main';
import LoadingSpinner from './utils/LoadingSpinner.vue';
import formCreateNewItem from './formCreateNewItem.vue';

    export default {
        components: {
            LoadingSpinner,
            formCreateNewItem,
        },
        data() {
            return {
                shoppingList: [],
                newItem: {
                    articolo: '',
                    quantità: '',
                    unità_misura: '',
                },
                showModal: false,
                loading: true,
            };
        },
        created() {
            this.fetchShoppingList();
        },
        methods: {
            addItem(newItem, section) {
                return addItem(newItem, section);
            },
            handleAddItemFromModal(newItem) {
                const existingItemIndex = this.shoppingList.findIndex(item => item.articolo === newItem.articolo);

                if (existingItemIndex !== -1) {
                    this.shoppingList[existingItemIndex].quantità += newItem.quantità;
                } else {
                    // Aggiungi anche l'unità di misura al nuovo articolo
                    this.shoppingList.push({
                        articolo: newItem.articolo,
                        quantità: newItem.quantità,
                        unità_misura: newItem.unità_misura,
                        checked: false // Inizializza il campo checked per il nuovo articolo
                    });

                    // Aggiungi l'articolo alla tabella added_items nel backend
                    axios.post('http://127.0.0.1:5000/api/add_item', newItem)
                        .then(() => {
                            console.log('Articolo aggiunto a added_items!');
                        })
                        .catch((error) => {
                            console.error('Errore nell\'aggiungere l\'articolo:', error);
                        });
                }

                this.showModal = false;
            },
            handleDeleteItem(articolo) {
                deleteItem(articolo, 'shoppingList')
                    .then(() => {
                        // Rimuovi l'articolo dall'inventario locale
                        this.shoppingList = this.shoppingList.filter(item => item.articolo !== articolo);
                        console.log('Articolo rimosso dall\'inventario locale.');

                        // Opzionale: eliminare anche dall'added_items se desiderato
                        axios.delete(`http://127.0.0.1:5000/api/remove_item/${articolo}`)
                            .then(() => {
                                console.log('Articolo rimosso da added_items!');
                            })
                            .catch((error) => {
                                console.error('Errore nell\'eliminare l\'articolo da added_items:', error);
                            });
                    })
                    .catch((error) => {
                        console.error('Errore nell\'eliminare l\'articolo dalla shopping list:', error);
                    });
            },

            async fetchShoppingList() {
                try {
                const response = await axios.get('http://127.0.0.1:5000/api/shoppingList');
                this.shoppingList = response.data;
                } catch (error) {
                console.error('Errore nel recupero della ShoppingList:', error);
                } finally {
                    this.loading = false; // Imposta loading a false quando i dati sono stati recuperati
                }
            },
            finishShopping() {
                const itemsToAdd = this.shoppingList.filter(item => item.checked);
                const addPromises = itemsToAdd.map(item => {
                    // Converti la quantità in numero prima di inviare
                    const itemToAdd = {
                        articolo: item.articolo,
                        quantità: Number(item.quantità) // o parseFloat(item.quantità)
                    };
                    return addItem(itemToAdd, 'inventory');
                });

                Promise.all(addPromises)
                    .then(() => {
                        console.log('Tutti gli articoli aggiunti con successo all\'inventario!');
                        // Ora elimina gli articoli dalla lista della spesa
                        const deletePromises = itemsToAdd.map(item => {
                            return deleteItem(item.articolo, 'shoppingList');
                        });

                        return Promise.all(deletePromises);
                    })
                    .then(() => {
                        console.log('Tutti gli articoli eliminati dalla shopping list!');
                        // Aggiorna la lista della spesa per rimuovere gli articoli aggiunti
                        this.shoppingList = this.shoppingList.filter(item => !item.checked);
                    })
                    .catch((error) => {
                        console.error('Errore nel processo di Spesa finita:', error);
                    });
            }
        },
        mounted() {
            this.fetchShoppingList(); // Carica l'inventario all'avvio
        },
    };
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../scss/styles.scss";

#ulContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    max-height: 70vh;
}

ul {
    padding: 0;
    margin: 0;
    width: 100%;
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    list-style-type: none;
}

li {
    width: 40%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    text-align: center;
}


#emptyShoppingList{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.checkbox-wrapper-15{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 5px 0;
}

.checkbox-wrapper-15 .cbx {
-webkit-user-select: none;
user-select: none;
-webkit-tap-highlight-color: transparent;
cursor: pointer;
}
.checkbox-wrapper-15 .cbx span {
display: inline-block;
vertical-align: middle;
transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-15 .cbx span:first-child {
position: relative;
width: 24px;
height: 24px;
border-radius: 50%;
transform: scale(1);
vertical-align: middle;
border: 1px solid #B9B8C3;
transition: all 0.2s ease;
}
.checkbox-wrapper-15 .cbx span:first-child svg {
position: absolute;
z-index: 1;
top: 8px;
left: 6px;
fill: none;
stroke: white;
stroke-width: 2;
stroke-linecap: round;
stroke-linejoin: round;
stroke-dasharray: 16px;
stroke-dashoffset: 16px;
transition: all 0.3s ease;
transition-delay: 0.1s;
transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-15 .cbx span:first-child:before {
content: "";
width: 100%;
height: 100%;
background: rgba(127, 127, 127, 0.562);
display: block;
transform: scale(0);
opacity: 1;
border-radius: 50%;
transition-delay: 0.2s;
}
.checkbox-wrapper-15 .cbx span:last-child {
margin-left: 8px;
}
.checkbox-wrapper-15 .cbx span:last-child:after {
content: "";
position: absolute;
top: 50%; /* Posiziona il pseudo-elemento al 50% dell'altezza del suo genitore */
left: 0;
height: 1px;
width: 100%;
background: #B9B8C3;
transform-origin: 0 0;
transform: scaleX(0) translateY(-50%); /* Centra verticalmente */
}
.checkbox-wrapper-15 .cbx:hover span:first-child {
border-color: rgba(127, 127, 127, 0.562);
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child {
border-color: rgba(127, 127, 127, 0.562);
background: rgba(127, 127, 127, 0.562);
animation: check-15 0.6s ease;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child svg {
stroke-dashoffset: 0;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:first-child:before {
transform: scale(2.2);
opacity: 0;
transition: all 0.6s ease;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child {
color: #B9B8C3;
transition: all 0.3s ease;
}
.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child:after {
transform: scaleX(1);
transition: all 0.3s ease;
}

@keyframes check-15 {
    50% {
        transform: scale(1.2);
    }
}


</style>
