<template>
    <div class="loading-spinner">
      <p>Caricamento in corso...</p>
      <div class="spinner"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'LoadingSpinner',
  };
  </script>
  
  <style scoped>
 .loading-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centra sia orizzontalmente che verticalmente */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  border: 8px solid #f3f3f3; /* Colore di sfondo */
  border-top: 8px solid #3498db; /* Colore del cerchio */
  border-radius: 50%; /* Forma circolare */
  width: 50px; /* Dimensioni del cerchio */
  height: 50px; /* Dimensioni del cerchio */
  animation: spin 1s linear infinite; /* Animazione */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

  </style>
  