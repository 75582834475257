<template>
  <div>

    <!-- Navigation bar -->
    <nav class="nav">
      <router-link class="nav-link" to="/currentInventory" exact-active-class="active">Inventario</router-link>
      <router-link class="nav-link" to="/shopping-list" exact-active-class="active">Shopping List</router-link>
      <router-link class="nav-link" to="/must-be" exact-active-class="active">Cosa deve esserci</router-link>
    </nav>

    <router-view id="all"></router-view>
  </div>
  
</template>

<script>
export default {
  name: 'App',
  components: {

  }
}
</script>

<style lang="scss" scoped>
@import "./scss/styles.scss";

* {
  font-family: 'Roboto', sans-serif;
}

#all{
  margin: 0 50px;
}

.nav {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.nav-link {
  align-self: center;
  background-color: #fff;
  background-image: none;
  background-position: 0 90%;
  background-repeat: repeat no-repeat;
  background-size: 4px 3px;
  border-radius: 15px 225px 255px 15px 15px 255px 225px 15px;
  border-style: solid;
  border-width: 2px;
  box-shadow: rgba(0, 0, 0, .2) 15px 28px 25px -18px;
  box-sizing: border-box;
  color: #41403e;
  cursor: pointer;
  display: inline-block;
  font-family: Neucha, sans-serif;
  font-size: 1rem;
  line-height: 23px;
  outline: none;
  padding: .75rem;
  text-decoration: none;
  transition: all 235ms ease-in-out;
  border-bottom-left-radius: 15px 255px;
  border-bottom-right-radius: 225px 15px;
  border-top-left-radius: 255px 15px;
  border-top-right-radius: 15px 225px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.nav-link.active {
  background-color: rgba(127, 127, 127, 0.562);
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
}

.nav-link:hover {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 8px -5px;
  color: #41403e;
}

.nav-link:focus {
  box-shadow: rgba(0, 0, 0, .3) 2px 8px 4px -6px;
  color: #41403e;
}

</style>
