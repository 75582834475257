<template>
  <div v-if="isVisible" class="main">
    <div class="modal-content">
      <span class="close-btn" @click="closeModal">&times;</span>
      <h3>{{ isEditing ? 'Modifica Articolo' : 'Aggiungi un nuovo articolo' }}</h3>

      <form @submit.prevent="handleSubmit" class="form">
          <div class="form-group">
              <input 
                  type="text" 
                  v-model="newItem.articolo" 
                  placeholder="Nome dell'articolo" 
                  required 
                  class="form-input"
              />
              <input 
                  type="number"
                  v-model="newItem.quantità"
                  placeholder="Quantità"
                  required
                  class="form-input"
              />
              <select v-model="newItem.unità_misura" class="form-input" required>
                <option disabled value="">Seleziona unità di misura</option>
                <option value="kg">kg</option>
                <option value="pz">pz</option>
                <option value="g">g</option>
              </select>
          </div>
          <button class="addArticleBtn">{{ isEditing ? 'Aggiorna Articolo' : 'Aggiungi Articolo' }}</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    addItem: {
      type: Function,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    item: { // Aggiungi prop per l'articolo da modificare
      type: Object,
      default: null, // Cambiato per poter gestire null
    },
  },
  data() {
    return {
      newItem: {
        articolo: this.item && this.item.articolo ? this.item.articolo : '', // Controllo su item
        quantità: this.item && this.item.quantità ? this.item.quantità : 0, // Controllo su item
        unità_misura: this.item && this.item.unità_misura ? this.item.unità_misura : '',
      },
    };
  },
  computed: {
    isEditing() {
      return this.item && this.item.articolo; // Determina se stiamo modificando un articolo
    }
  },
  watch: {
    item: {
      handler(newVal) {
        if (newVal) {
          this.newItem.articolo = newVal.articolo;
          this.newItem.quantità = newVal.quantità;
          this.newItem.unità_misura = newVal.unità_misura;
        } else {
          this.resetForm(); // Resetta quando l'oggetto è null
        }
      },
      immediate: true, // Esegui subito la funzione al caricamento del componente
    },
    isVisible(val) {
      if (!val) {
        this.resetForm(); // Resetta quando la modale si chiude
      }
    }
  },
  methods: {
    handleSubmit() {
      if (this.isEditing) {
        this.updateItem(); // Se stiamo modificando, chiama il metodo di aggiornamento
      } else {
        this.addItem(this.newItem, this.section) // Altrimenti, aggiungi un nuovo articolo
          .then(() => {
            console.log('Articolo aggiunto con successo!', this.newItem);
            this.$emit('add-item', { ...this.newItem });
            this.resetForm();
            this.closeModal();
          })
          .catch((error) => {
            console.error('Errore nell\'aggiungere l\'articolo:', error);
          });
      }
    },
    updateItem() {
      // Emetti un evento per aggiornare l'articolo
      this.$emit('update-item', { ...this.newItem });
      this.resetForm();
      this.closeModal();
    },
    resetForm() {
      this.newItem.articolo = '';
      this.newItem.quantità = 0;
      this.newItem.unità_misura = '';
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";

.main {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.form {
  display: flex;
  flex-direction: column;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 24px;
  color: #888;
  transition: color 0.3s;

  &:hover {
    color: #333;
  }
}

h3 {
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
}

.form-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;

  &:focus {
    border-color: #4CAF50;
    outline: none;
  }
}
</style>
