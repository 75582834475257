<template>
  <div v-if="message" :class="['notification', type]">
    {{ message }}
  </div>
</template>
  
<script>
export default {
  name: 'NotificationMessage',
  props: {
    message: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'success' // success or error
    }
  }
};
</script>
  
<style lang="scss" scoped>
@import "../../scss/styles.scss";

  .notification {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    z-index: 1000;
  }
  
  .notification.success {
    background-color: #4CAF50; /* Verde */
    color: white;
  }
  
  .notification.error {
    background-color: #f44336; /* Rosso */
    color: white;
  }
  </style>
  