import { createApp } from 'vue';
import App from './App.vue';
import 'bootstrap';
import { createRouter, createWebHistory } from 'vue-router';
import './scss/styles.scss';

import CurrentInventory from './components/CurrentInventory.vue';
import ShoppingList from './components/ShoppingList.vue';
import MustBe from './components/MustBe.vue';

const routes = [
    { path: '/', component: CurrentInventory },
    { path: '/currentInventory', component: CurrentInventory },
    { path: '/shopping-list', component: ShoppingList },
    { path: '/must-be', component: MustBe },
];
  
const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Funzione globale per aggiungere un articolo
export function addItem(newItem, section) {
    return fetch(`http://127.0.0.1:5000/api/${section}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(newItem),
    })
    .catch((error) => {
        console.error('Error:', error);
        throw error;
    });
}

// Funzione globale per eliminare un articolo
export function deleteItem(articolo, section) {
    return fetch(`http://127.0.0.1:5000/api/${section}/${articolo}`, {
        method: 'DELETE',
    })
    .then(response => response.json())
    .then(data => {
        console.log('Articolo eliminato:', data);
        // Non restituiamo più l'inventario filtrato
    })
    .catch((error) => {
        console.error('Error:', error);
        throw error;
    });
}

// Funzione globale per aggiornare un articolo
export function updateItem(articolo, updatedData, section) {
    return fetch(`http://127.0.0.1:5000/api/${section}/${articolo}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedData),
    })
    .then(response => {
        if (!response.ok) {
            throw new Error('Failed to update item');
        }
        return response.json();
    })
    .then(data => {
        console.log('Articolo aggiornato:', data);
        return data;
    })
    .catch((error) => {
        console.error('Error:', error);
        throw error;
    });
}

createApp(App).use(router).mount('#app');
